import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/Error404.scss';

const Error404: React.FC = () => (
    <div id="error-404-page">
        <h1>Não encontramos a página que você procura...</h1>
        <Link to="/">
            Ir para página inicial
        </Link>
    </div>
);

export default Error404;
