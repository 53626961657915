import React from 'react';
import { Link } from 'react-router-dom';
import { useDateReveal } from '../contexts/DateRevealContext';

import BannerImage from '../assets/images/banner-image.png';

import '../styles/SignUpBanner.scss';

const SignUpBanner: React.FC = () => {
    const { isDateRevealed } = useDateReveal(); // Usando o contexto

    return (
        <div id="wrapper-banner">
            <div className="sign-up-banner">
                <div>
                    <h1>
                        seu evento de tecnologia
                        {' '}
                        <span id="secondary-color"> favorito</span>
                        .
                    </h1>
                </div>
                <p>
                    {isDateRevealed ? (
                        'nos dias 1, 2 e 3 de outubro, dê seus primeiros passos na carreira de tecnologia!'
                    ) : (
                        'nos dias [REDACTED 👀] de [REDACTED 👀], dê seus primeiros passos na carreira de tecnologia!'
                    )}
                </p>
                <div className="actions">
                    <Link
                        style={{
                            pointerEvents: isDateRevealed ? 'auto' : 'none',
                            opacity: isDateRevealed ? 1 : 0.5,
                        }}
                        to="/signup"
                    >
                        inscreva-se
                    </Link>
                    {!isDateRevealed && (
                        <p style={{ marginTop: 20 }}>*as inscrições ainda não estão abertas.</p>
                    )}
                </div>
            </div>
            <div id="banner-image">
                <img src={BannerImage} alt="" />
            </div>
        </div>
    );
};

export default SignUpBanner;
