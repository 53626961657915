import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getCookie } from 'react-use-cookie';
import { persistCache } from 'apollo3-cache-persist';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_GRAPHQL || 'http://localhost:1337/graphql',
});

const authLink = setContext((_, { headers }) => {
    const token = getCookie('pixelinit.token');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const cache = new InMemoryCache();

persistCache({
    cache,
    storage: localStorage,
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
});

export default client;
