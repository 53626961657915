/* eslint-disable camelcase */
import React from 'react';
import { DayLecturesList_lectures } from '../../constants/graphql/__generated__/DayLecturesList';
import Dialog, { DialogProps } from '../Dialog';
import Lecture from '../Lecture';

import '../../styles/DialogLectureDetails.scss';

interface DialogLectureDetailsProps extends Omit<DialogProps, 'title'> {
    data: DayLecturesList_lectures
}

const DialogLectureDetails: React.FC<DialogLectureDetailsProps> = ({ data, ...props }) => (
    <Dialog {...props} title="Detalhes da palestra">
        <div id="dialog-lecture-details-component">
            {
                data && (
                    <Lecture
                        data={data}
                        shortDescription={false}
                    />
                )
            }
        </div>
    </Dialog>
);

export default DialogLectureDetails;
