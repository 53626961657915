import React from 'react';
import '../styles/SectionTitle.scss';
import Vetor from '../assets/images/vetor.png';

interface Props {
    title: string,
}

const SectionTitle: React.FC<Props> = ({ title }) => (
    <div className="flex-center" id="title-wrapper">
            <div id="section-title">
                <div className="flex-center" id="vetor">
                    <img src={Vetor} alt="" />
                </div>
                <h2>{title}</h2>
            </div>
    </div>
);

export default SectionTitle;
