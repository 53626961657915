/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, { useEffect } from 'react';

import CloseIcon from '../assets/svgs/close.svg';

import '../styles/Dialog.scss';

export interface DialogProps {
    visible: boolean
    onDismiss(): void,
    title: string
}

const Dialog: React.FC<DialogProps> = ({
    children, visible, onDismiss, title,
}) => {
    useEffect(() => {
        if (visible) {
            document.body.classList.add('dialog-visible');
        } else {
            document.body.classList.remove('dialog-visible');
        }
    }, [visible]);

    return (
        <div
            onClick={onDismiss}
            className={classNames('dialog-component', {
                visible,
            })}
        >
            <div onClick={(e) => e.stopPropagation()} className="dialog-wrapper">
                <div className="dialog-header">
                    <span>{title}</span>
                    <button onClick={() => onDismiss()} type="submit">
                        <img src={CloseIcon} alt="Fechar" />
                    </button>
                </div>
                <div className="dialog-body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Dialog;
