/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDateReveal } from '../contexts/DateRevealContext'; // Importa o contexto para verificar se a data foi revelada

import useSpeakers from '../hooks/useSpeakers';
import NavButton from './NavButton';
import InstagramIcon from '../assets/svgs/instagram-icon.svg';
import TwitterIcon from '../assets/svgs/twitter-icon.svg';
import YoutubeIcon from '../assets/svgs/youtube-icon.svg';
import LinkedinIcon from '../assets/svgs/linkedin-icon.svg';
import NoAvatar from '../assets/images/no-avatar.png';
import Loader from './Loader';

import '../styles/CarouselSection.scss';

interface Speaker {
  id: number;
  attributes: {
    fullName: string;
    description?: string;
    avatar: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    email: string;
    linkedin_link?: string;
    twitter_link?: string;
    youtube_link?: string;
    instagram_link?: string;
  };
}

const CarouselSection: React.FC = () => {
  const { data, loading, error } = useSpeakers();
  const { isDateRevealed } = useDateReveal();

  function onAvatarImageError(e: any) {
    e.target.onerror = null;
    e.target.src = NoAvatar;
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4, // Quantos slides mostrar no desktop
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2, // Quantos slides mostrar no tablet
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2, // Quantos slides mostrar no mobile
    },
  };

  // Verifica se a data foi revelada
  if (!isDateRevealed) {
    // eslint-disable-next-line react/style-prop-object
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="error">
        Em breve...
      </div>
    );
  }

  return (
    <div id="carousel-wrapper">
      {loading ? (
        <div className="loading">
          <Loader color="var(--brand-secondary)" />
        </div>
      ) : error || !data || data.length === 0 ? (
        <div className="error">
          <p>Em breve...</p>
        </div>
      ) : (
        <Carousel
          responsive={responsive}
          infinite
          slidesToSlide={2}
          autoPlay
          autoPlaySpeed={3000}
          keyBoardControl
          showDots
          customLeftArrow={<NavButton direction="left" />}
          customRightArrow={<NavButton direction="right" />}
        >
          {data?.map((speaker: Speaker) => {
            const avatarUrl = speaker.attributes.avatar?.data?.attributes?.url
              ? `${process.env.REACT_APP_API}${speaker.attributes.avatar.data.attributes.url}`
              : NoAvatar;

            return (
              <div key={speaker.id} id="carousel-item">
                <div>
                  <img
                    src={avatarUrl}
                    onError={onAvatarImageError}
                    alt="Sem avatar"
                    title={speaker.attributes.fullName}
                    className="profile"
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <div id="text-wrapper">
                  <h2>{speaker.attributes.fullName}</h2>
                  <p>{speaker.attributes.description}</p>
                  <div id="social-icons">
                    {speaker.attributes.instagram_link && (
                      <a
                        href={speaker.attributes.instagram_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={InstagramIcon} alt="instagram" />
                      </a>
                    )}
                    {speaker.attributes.twitter_link && (
                      <a
                        href={speaker.attributes.twitter_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={TwitterIcon} alt="twitter" />
                      </a>
                    )}
                    {speaker.attributes.youtube_link && (
                      <a
                        href={speaker.attributes.youtube_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={YoutubeIcon} alt="youtube" />
                      </a>
                    )}
                    {speaker.attributes.linkedin_link && (
                      <a
                        href={speaker.attributes.linkedin_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={LinkedinIcon} alt="linkedin" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default CarouselSection;
