import React from 'react';

import LogoBranca from '../assets/images/logo-outline-branco.png';
import '../styles/Footer.scss';
// import Sponsors from '../assets/images/google.png';
import InstaIcon from '../assets/images/insta.png';
import YoutubeIcon from '../assets/images/youtube.png';

const Footer: React.FC = () => (
    <div id="footer-background">
        <footer id="footer">
            <div id="footer-start">
                <div id="footer-wrapper">
                    <div id="logo-wrapper">
                        <img src={LogoBranca} alt="" />
                    </div>
                    <div id="social-wrapper">
                        <h3>redes sociais</h3>
                        <div id="social-icons">
                            {/* <a target="_blank" rel="noreferrer" href="https://www.facebook.com/ejpixel/">
                                <img src={FaceIcon} alt="facebook" />
                            </a> */}
                            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/pixelinit/">
                                <img src={InstaIcon} alt="instagram" />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCswqntcL5CQ5DfIvIRhw5Mw">
                                <img src={YoutubeIcon} alt="youtube" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="assinature">
                <p>
                    Organizado por
                    {' '}
                    <a href="https://ejpixel.com.br" target="_blank" rel="noreferrer">Pixel Soluções Digitais</a>
                </p>
            </div>
        </footer>
    </div>
);

export default Footer;
