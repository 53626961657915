import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../hooks/useQueryParams';

import Loader from '../components/Loader';

import '../styles/EmailConfirmation.scss';

const EmailConfirmation: React.FC = () => {
    const history = useHistory();
    const query = useQueryParams();

    const token = query.get('token') || undefined;

    useEffect(() => {
        (async () => {
            if (token !== undefined) {
                await fetch(`${process.env.REACT_APP_API}/auth/email-confirmation?confirmation=${token}`);
                history.push('/login');
            } else {
                history.push('/login');
            }
        })();
    }, [token]);

    return (
        <div id="email-confirmation-page">
            <Loader />
        </div>
    );
};

export default EmailConfirmation;
