import React from 'react';
import '../styles/InitBackgroundEnd.scss';

const InitBackground: React.FC = ({ children }) => (
    <div id="init-background-end">
        { children }
    </div>
    );

export default InitBackground;
