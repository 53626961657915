import React from 'react';
import '../styles/PhotoSection.scss';
import AboutPhoto from '../assets/images/about-photo-section.png';

const PhotoSection: React.FC = () => (
    <div id="photo-section">
        <div id="photo-overlay">
            <img src={AboutPhoto} alt="" />
        </div>
    </div>
);

export default PhotoSection;
