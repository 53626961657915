/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */

import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import Loader from './Loader';
import '../styles/Button.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  isLoading?: boolean;
  bgColor?: 'primary' | 'secondary' | 'regular' | string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button', // Definimos um valor padrão aqui
  bgColor = 'primary',
  isLoading = false,
  style,
  ...props
}) => {
  const usingBgCustomColor = !['primary', 'secondary', 'regular'].includes(
    bgColor,
  );

  // Garante que `type` seja sempre uma string literal ou expressão trivial
  const buttonType = type || 'button';

  return (
    <button
      type={buttonType} // Agora garantido que `buttonType` é uma string literal
      className={classNames('button-component', {
        'primary-color': bgColor === 'primary',
        'secondary-color': bgColor === 'secondary',
        'regular-color': bgColor === 'regular',
      })}
      style={{
        ...style,
        backgroundColor: usingBgCustomColor ? bgColor : style?.backgroundColor,
      }}
      {...props}
    >
      {!isLoading ? children : <Loader />}
    </button>
  );
};

export default Button;
