/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import Input from '../components/Input';
import InputError from '../components/InputError';
import Button from '../components/Button';
import LeftHeroContent from '../components/LeftHeroContent';

import '../styles/SignUp.scss';

interface FormData {
    fullName: string;
    email: string;
}

const SignUp: React.FC = () => {
    const [awaitingConfirmEmail, setAwaitingConfirmEmail] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false); // Estado de carregamento

    const {
        register, formState: { errors }, handleSubmit, setError,
    } = useForm<FormData>();

    // Função para enviar os dados para o Strapi
    const onSubmit = useCallback(async (data: FormData) => {
        // Ativa o estado de carregamento ao submeter o formulário
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/subscribers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        name: data.fullName,
                        email: data.email,
                    },
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                 // eslint-disable-next-line
                console.error('Erro ao cadastrar:', errorData);  // Loga o erro completo
                setError('email', {
                    type: 'error',
                    message: errorData.error.message || 'Erro ao cadastrar. Verifique seus dados e tente novamente.',
                });
            } else {
                const result = await response.json();
                 // eslint-disable-next-line
                // console.log('Cadastro realizado com sucesso:', result);
                setAwaitingConfirmEmail(true);
            }
        } catch (error) {
             // eslint-disable-next-line
            console.error('Erro inesperado:', error);
            setError('email', {
                type: 'error',
                message: 'Erro inesperado. Tente novamente mais tarde.',
            });
        } finally {
            // Desativa o estado de carregamento após a requisição
            setLoading(false);
        }
    }, [setError]);

    return (
        <div id="sign-up-page" className="signin-signup-view">
            <div className="row-wrapper">
                <LeftHeroContent />
                <main>
                    {
                        awaitingConfirmEmail
                            ? (
                                
                                <>
                                    <span className="title">Bem-vindo ao Pixel Init!</span>
                                    <span className="tip">
                                        Sua inscrição foi realizada com sucesso.
                                        Verifique sua caixa de entrada para mais informações.
                                    </span>
                                    <span className="tipAux">
                                    Enquanto isso, junte-se à nossa <strong>comunidade exclusiva no WhatsApp</strong> e fique conectado com outros participantes, além de receber informações em primeira mão.
Nossas comunicações se darão tanto pelo email quanto pela comunidade!
                                    </span>
                                    <Button bgColor="#59ce72" onClick={() => window.open('https://chat.whatsapp.com/invite/8DQ1h8X1OQv3m4J4h6j3kq', '_blank')}>
                                        Entre na nossa comunidade
                                    </Button>
                                </>
                             
                            )
                            : (
                                <>
                                    <span className="title">Faça a sua inscrição abaixo.</span>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Input
                                            {...register('fullName', { required: true })}
                                            hasError={errors.fullName !== undefined}
                                            type="text"
                                            placeholder="Nome completo"
                                        />
                                        <InputError visible={errors.fullName?.type === 'required'} message="Insira seu nome completo" />

                                        <Input
                                            {...register('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
                                            hasError={errors.email !== undefined}
                                            autoComplete="on"
                                            type="email"
                                            placeholder="E-mail"
                                        />
                                        <InputError visible={errors.email?.type === 'required'} message="Insira um e-mail" />
                                        <InputError visible={errors.email?.type === 'pattern'} message="Insira um e-mail válido" />
                                        <InputError visible={errors.email?.type === 'error'} message={errors.email?.message || ''} />

                                        <Button bgColor="secondary" type="submit" disabled={loading}>
                                            {loading ? 'Enviando...' : 'vamos lá'}
                                        </Button>
                                    </form>
                                </>
                            )
                    }
                </main>
            </div>
        </div>
    );
};

export default SignUp;
