import React from 'react';
import '../styles/NavButton.scss';

interface Props {
    direction: string;
}

const NavButton: React.FC<Props> = ({ direction }) => {
    if (direction === 'down') {
        return (
            <button type="button" id="botao" aria-label="Scroll down">
                <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2L16.8598 17.1402L32 2" stroke="#12C2E9" strokeWidth="3" />
                </svg>
            </button>
        );
    }
    if (direction === 'up') {
        return (
            <button type="button" id="botao" aria-label="Scroll up">
                <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.28662 13.0468L11.1932 2.95329L21.2866 13.0468" stroke="#12C2E9" strokeWidth="3" />
                </svg>
            </button>
        );
    }
    if (direction === 'left') {
        return (
            <button type="button" id="botao" aria-label="Scroll left">
                <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.3334 22L2.23992 12.0935L12.3334 2" stroke="#12C2E9" strokeWidth="3" />
                </svg>
            </button>
        );
    }
    if (direction === 'right') {
        return (
            <button type="button" id="botao" aria-label="Scroll right">
                <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.66663 22L11.7601 12.0935L1.66663 2" stroke="#12C2E9" strokeWidth="3" />
                </svg>
            </button>
        );
    }
    return (
        <button type="button" id="botao" aria-label="Scroll down">
            <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2L16.8598 17.1402L32 2" stroke="#12C2E9" strokeWidth="3" />
            </svg>
        </button>
    );
};

export default NavButton;
