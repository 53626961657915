import React from 'react';

import '../styles/Loader.scss';

interface LoaderProps {
    color?: string
}

const Loader: React.FC<LoaderProps> = ({ color = 'white' }) => (
    <div className="lds-ellipsis">
        <div style={{
            backgroundColor: color,
        }}
        />
        <div style={{
            backgroundColor: color,
        }}
        />
        <div style={{
            backgroundColor: color,
        }}
        />
        <div style={{
            backgroundColor: color,
        }}
        />
    </div>
);

export default Loader;
