import React from 'react';

import '../styles/Input.scss';

interface InputErrorProps {
    visible: boolean
    message: string
}

const InputError: React.FC<InputErrorProps> = ({ visible, message }) => (
    // eslint-disable-next-line react/no-danger
    visible ? <span className="input-error" dangerouslySetInnerHTML={{ __html: message.replaceAll('\n', '<br>') }} /> : <></>
);

export default InputError;
