import { useState, useEffect } from 'react';

const useSpeakers = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchSpeakers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/speakers?populate=*`);
                if (!response.ok) {
                    throw new Error(`Error fetching speakers: ${response.statusText}`);
                }
                const result = await response.json();
                setData(result.data);
            } catch (err: unknown) {
                // Garantimos que 'err' é do tipo correto e verificamos se ele tem uma mensagem
                if (err instanceof Error) {
                    setError(err.message); // Usamos a mensagem de erro
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchSpeakers();
    }, []);

    return { data, loading, error };
};

export default useSpeakers;
