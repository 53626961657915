/* eslint-disable no-console */
/* eslint-disable jsx-quotes */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable max-len */

import React, {
    useEffect, useMemo, useState, useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import removeMd from 'remove-markdown';
import classNames from 'classnames';
import Button from './Button';
import Card from './Card';
import NoAvatar from '../assets/images/no-avatar.png';
import InstagramIcon from '../assets/svgs/instagram-icon.svg';
import TwitterIcon from '../assets/svgs/twitter-icon.svg';
import YoutubeIcon from '../assets/svgs/youtube-icon.svg';
import LinkedinIcon from '../assets/svgs/linkedin-icon.svg';

import '../styles/Lecture.scss';
import DialogLectureDetails from './dialogs/DialogLectureDetails';

interface LectureProps extends React.HTMLAttributes<HTMLDivElement> {
    data: any;
    shortDescription?: boolean;
    canViewDetails?: boolean;
}

export const categoriesMap: { [key: string]: string } = {
    technical: 'eixo técnico',
    marketing: 'eixo mercadológico',
    academic: 'eixo acadêmico',
    social: 'eixo social',
    extra: 'conteúdo extra',
};

const Lecture: React.FC<LectureProps> = ({
    data, shortDescription = true, canViewDetails = false, ...props
}) => {
    const category = useMemo(() => categoriesMap[data.category] ?? 'conteúdo', [data]);

    const hour = useMemo(() => {
        const date = new Date(data.date);
        return date.toLocaleTimeString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'America/Sao_Paulo', // Ajuste para o fuso horário desejado
        });
    }, [data]);

    const description = shortDescription
        ? useMemo(() => removeMd(data.description), [data])
        : data.description;

    const [isDialogLectureDetailsVisible, setIsDialogLectureDetailsVisible] = useState<boolean>(false);
    const [isOnAir, setIsOnAir] = useState<boolean>(false);

    useEffect(() => {
        function checkIfIsOnLive() {
            const today = new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
            const eventDate = new Date(data.date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
            const eventEndDate = new Date(data.end_date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });

            setIsOnAir(today >= eventDate && today <= eventEndDate);
        }

        let interval: any;

        if (data.is_live) {
            checkIfIsOnLive();
            interval = setInterval(checkIfIsOnLive, 1000 * 30); // 30 segundos
        }

        return () => {
            if (interval) {
                clearTimeout(interval);
            }
        };
    }, [data.is_live]);

    const onAvatarImageError = useCallback((e: any) => {
        e.target.onerror = null;
        e.target.src = NoAvatar;
    }, []);

    const handleOpenLecture = useCallback((event: any) => {
        event.stopPropagation();
        window.open(data.youtube_link as any, '_blank');
    }, [data.youtube_link]);

    const handleCardClick = useCallback(() => {
        if (canViewDetails) {
            setIsDialogLectureDetailsVisible(true);
        }
    }, [canViewDetails]);

    return (
        <>
            <Card
                className={classNames('lecture-component', {
                    'on-air': isOnAir,
                })}
                {...props}
                onClick={handleCardClick}
            >
                <div className="on-air-now">
                    <div />
                    <span>ao vivo agora</span>
                </div>
                <div className="lecture-label">
                    <p>{category}</p>
                </div>

                <div className="lecture-form first">
                    <div className="p1 title-and-content">
                        <span>horário</span>
                        <p>{hour}</p>
                    </div>

                    <div className="p2 title-and-content">
                        <span>título da palestra</span>
                        <p>{data.title}</p>
                    </div>
                </div>

                <div className="lecture-form second title-and-content">
                    <span>descrição da palestra</span>
                    <p>
                        {shortDescription
                            ? description.length > 300
                                ? `${description.substring(0, 300)}...`
                                : description
                            : <ReactMarkdown>{description}</ReactMarkdown>}
                    </p>
                </div>

                <div className="lecture-form third">
                    <div className="p1 title-and-content">
                        {data?.speaker && (
                            <>
                                <span>Palestrante</span>
                                <p>{data.speaker}</p>
                                {console.log(data)}
                                <div id='social-icons'>
                                {data.instagram_link && (
                                    <a href={data.instagram_link} target='_blank' rel='noreferrer'>
                                        <img src={InstagramIcon} alt='instagram' />
                                    </a>
                                )}

                                {data.twitter_link && (
                                    <a href={data.twitter_link} target='_blank' rel='noreferrer'>
                                        <img src={TwitterIcon} alt='twitter' />
                                    </a>
                                )}

                                {data.youtube_link && (
                                    <a href={data.youtube_link} target='_blank' rel='noreferrer'>
                                        <img src={YoutubeIcon} alt='youtube' />
                                    </a>
                                )}

                                {data.linkedin_link && (
                                    <a href={data.linkedin_link} target='_blank' rel='noreferrer'>
                                        <img src={LinkedinIcon} alt='linkedin' />
                                    </a>
                                )}

                                </div>
                            </>
                        )}
                        {data?.speakers && data?.speakers.length > 0 && (
                            <>
                                <span>{data?.speakers.length === 1 ? 'palestrante' : 'palestrantes'}</span>
                                {data?.speakers.map((speaker: any) => (
                                    <div key={speaker?.url} className="profile">
                                        <Link
                                            to={`/app/palestrante/${speaker?.url}`}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <img
                                                className="avatar"
                                                src={speaker?.avatar ? `${process.env.REACT_APP_API}${speaker?.avatar.url}` : NoAvatar}
                                                onError={onAvatarImageError}
                                                title={speaker?.fullName}
                                                alt="palestrante"
                                            />
                                            <p>{speaker?.fullName}</p>
                                        </Link>

                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                    {data.youtube_link && (
                        <div className="p2">
                            <Button onClick={handleOpenLecture} type="button">
                                acessar no youtube
                            </Button>
                        </div>
                    )}
                </div>
            </Card>
            {canViewDetails && (
                <DialogLectureDetails
                    visible={isDialogLectureDetailsVisible}
                    onDismiss={() => setIsDialogLectureDetailsVisible(false)}
                    data={data}
                />
            )}
        </>
    );
};

export default Lecture;
