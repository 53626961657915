/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

//! TODO
// 1 - Botão de voltar para a página anterior (home do pixelinit)
// pode ser na logo ou em um botão separado
// 2 - Textos do https://docs.google.com/document/d/1UpFe_pH4nRICmNT2NBTmOG9Mvw3Xnm_YOBEp7yW3-HA/edit
// Tenta seguir bem a estrutura do documento, acho que tá bem legível assim.
// 3 - Em alguns lugares do documento tem links, por exemplo o email do pixelinit, lembra deixar ele em destaque.

// Sobre UI - pode se basear bem no que esta na home do pixelinit, acho que tá bem bonito
// Estilos pode fazer em css normal mesmo
// Lembra de fazer responsivo, mas como é tudo texto acho que vai ser bem tranquilo
// o CSS do SignUp, ele ta em /styles/SignUp.scss é um bom exemplo de como tu pode seguir

// Possivelmente vais encontrar algum erro de linting, mas o terminal geralmente mostra bem o que é necessário corrigir
// Se tiveres alguma dúvida, podes me perguntar

import React from 'react';

// estilos do css :)
import '../styles/terms.styles.css';

const Terms = () => (
  <div className="terms-container">
    <h1 className="terms-title">Termos de Serviço</h1>
    <p className="terms-text">
      Estes são os nossos termos de serviço. Ao utilizar nosso site, você concorda com os termos e condições descritos aqui. Por favor, leia com atenção antes de continuar a usar o site.
    </p>
  </div>
);

export default Terms;
