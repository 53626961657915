import React from 'react';
import CarouselSection from '../components/CarouselSection';
import InitBackground from '../components/InitBackground';
import LogoMenu from '../components/LogoMenu';
import PhotoSection from '../components/PhotoSection';
import ScheduleSection from '../components/ScheduleSection';
import SectionTitle from '../components/SectionTitle';
import SignUpBanner from '../components/SignUpBanner';
import SignUpBannerEnd from '../components/SignUpBannerEnd';
import SponsorsBanner from '../components/SponsorsBanner';
import TextSection from '../components/TextSection';
import InitBackgroundEnd from '../components/InitBackgroundEnd';
import Footer from '../components/Footer';
import { useDateReveal } from '../contexts/DateRevealContext'; // Importando o hook do contexto
import '../styles/PixelInitLanding.scss';

const PixelInitLanding: React.FC = () => {
    // Obtém os valores do contexto
    const { loading } = useDateReveal();

    if (loading) {
        return <div>Carregando...</div>; // Ou pode ser substituído por um componente de loading
    }

    return (
        <div id="pixel-landing-page">
            <InitBackground>
                <LogoMenu />
                <SignUpBanner />
            </InitBackground>
            <div id="sponsors-section">
                <SectionTitle title="patrocinadores" />
                <SponsorsBanner />
            </div>
            <div id="sobre-section">
                <SectionTitle title="sobre" />
                <div id="flex-center">
                    <div id="sobre-wrapper">
                        <PhotoSection />
                        <TextSection />
                    </div>
                </div>
            </div>
            <div id="palestrantes-section">
                <SectionTitle title="palestrantes" />
                <CarouselSection />
            </div>
            <div id="cronograma-section">
                <SectionTitle title="cronograma oficial" />
                {/* Não precisa passar isDateRevealed manualmente */}
                <ScheduleSection />
            </div>
            <div id="signup-banner">
                <InitBackgroundEnd>
                    <SignUpBannerEnd />
                </InitBackgroundEnd>
            </div>
            <Footer />
        </div>
    );
};

export default PixelInitLanding;
