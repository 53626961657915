import classNames from 'classnames';
import React, { forwardRef, InputHTMLAttributes } from 'react';

import '../styles/Input.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    hasError: boolean
}

// eslint-disable-next-line max-len
const Input: React.FC<InputProps> = forwardRef(({ hasError, className, ...props }, ref: any) => <input ref={ref} {...props} className={classNames(className, { error: hasError })} />);

export default Input;
