import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const PublicRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
    const auth = useAuth();

    return (
        <Route
            {...rest}
            render={() => (!auth ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: '/app',
                    }}
                />
            ))}
        />
    );
};

export default PublicRoute;
