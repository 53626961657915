import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { LoggedUserProvider } from './contexts/loggedUser';
import { DateRevealProvider } from './contexts/DateRevealContext';
import client from './services/client';
import App from './router';
import reportWebVitals from './reportWebVitals';

import './styles/_global.scss';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApolloProvider client={client}>
                <LoggedUserProvider>
                    <DateRevealProvider>
                        <App />
                    </DateRevealProvider>
                </LoggedUserProvider>
            </ApolloProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
