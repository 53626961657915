/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import VetorPrimary from '../assets/images/vetor-primary.png';
import VetorSecondary from '../assets/images/vetor-secondary.png';
import { categoriesMap } from './Lecture';
import '../styles/ScheduleSection.scss';
import Loader from './Loader';
import DialogLectureDetails from './dialogs/DialogLectureDetails';
import { useDateReveal } from '../contexts/DateRevealContext';

interface ScheduleItemProps {
  list: any[];
  type: string;
}

const ScheduleItem: React.FC<ScheduleItemProps> = ({ list, type }) => {
  // eslint-disable-next-line max-len
  const [isDialogLectureDetailsVisible, setIsDialogLectureDetailsVisible] = useState<boolean>(false);
  const [selectedLecture, setSelectedLecture] = useState<any>(null);

  function handleSelectLecture(lecture: any) {
    setSelectedLecture(lecture);
    setIsDialogLectureDetailsVisible(true);
  }

  if (!list || list.length === 0) {
    return (
      <div
        style={{
          maxWidth: 200,
          textAlign: 'center',
          margin: 'auto',
          opacity: 0.4,
          marginTop: 15,
        }}
      >
        <p>Logo logo você vai saber o que tem por aqui 👀</p>
      </div>
    );
  }

  return (
    <>
      {list.map((lecture) => {
        const lectureDate = new Date(lecture.date);
        const hour = `${String(lectureDate.getHours()).padStart(
          2,
          '0',
        )}:${String(lectureDate.getMinutes()).padStart(2, '0')}`;

        return (
          <div className="flex-center" key={lecture.id}>
            <div
              onClick={() => handleSelectLecture(lecture)}
              className="schedule-card"
              id={`card-${type}`}
            >
              <div id="title">
                <h3>{categoriesMap[lecture.category]}</h3>
              </div>
              <div id="card">
                <div id="card-flex">
                  <div id="card-time">
                    <h3>{hour}</h3>
                  </div>
                  <div id="card-title">
                    <h3>{lecture.title}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <DialogLectureDetails
        visible={isDialogLectureDetailsVisible}
        onDismiss={() => setIsDialogLectureDetailsVisible(false)}
        data={selectedLecture}
      />
    </>
  );
};

const ScheduleSection: React.FC = () => {
  const { isDateRevealed, loading } = useDateReveal();
  const [data, setData] = useState<any>({ dayOne: [], dayTwo: [], dayThree: [] });

  useEffect(() => {
    // Fazendo a chamada para a API do Strapi
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/schedule?populate=*`);

        if (!response.ok) {
          throw new Error(`Erro HTTP! status: ${response.status}`);
        }

        const result = await response.json();

        if (result.data && result.data.attributes) {
          // Acessando diretamente o objeto `data` dentro de `attributes`
          setData(result.data.attributes.data);
        } else {
          console.error('Estrutura de dados inesperada', result);
        }
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <Loader color="var(--brand-secondary)" />
      </div>
    );
  }

  // Caso a data não tenha sido revelada, exibe "Em breve..."
  if (!isDateRevealed) {
    return (
      <div className="error">
        <p>Em breve...</p>
      </div>
    );
  }

  return (
    <div className="flex-center">
      <div id="schedule-section">
        <div id="schedule-date-1">
          <div className="flex-center">
            <div className="day-title" id="card-1">
              <h2>dia 01/10</h2>
              <div className="flex-center">
                <img src={VetorSecondary} alt="" />
              </div>
            </div>
          </div>
          <ScheduleItem list={data.dayOne} type="secondary" />
        </div>
        <div id="schedule-date-2">
          <div className="flex-center">
            <div className="day-title" id="card-2">
              <h2>dia 02/10</h2>
              <div className="flex-center">
                <img src={VetorPrimary} alt="" />
              </div>
            </div>
          </div>
          <ScheduleItem list={data.dayTwo} type="primary" />
        </div>
        <div id="schedule-date-3">
          <div className="flex-center">
            <div className="day-title" id="card-3">
              <h2>dia 03/10</h2>
              <div className="flex-center">
                <img src={VetorSecondary} alt="" />
              </div>
            </div>
          </div>
          <ScheduleItem list={data.dayThree} type="secondary" />
        </div>
      </div>
    </div>
  );
};

export default ScheduleSection;
