import classNames from 'classnames';
import React from 'react';

import '../styles/Card.scss';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    borderType?: 'solid' | 'dashed'
}

const Card: React.FC<CardProps> = ({
    children, borderType = 'solid', className, ...props
}) => (
    <div {...props} className={classNames('card-component', borderType, className)}>
        {children}
    </div>
);

export default Card;
