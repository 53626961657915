import React from 'react';
import NavButton from './NavButton';
import '../styles/InitBackground.scss';

const InitBackground: React.FC = ({ children }) => (
    <div id="init-background">
        {children}
        <div id="container-botao">
            <a href="#sponsors-section" aria-label="Scroll to sponsors section">
                <NavButton direction="down" />
            </a>
        </div>
    </div>
);

export default InitBackground;
