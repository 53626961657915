import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDateReveal } from '../contexts/DateRevealContext'; // Importa o contexto

import Logo from '../assets/images/logo-init-branco.png';
import MenuIcon from '../assets/images/sandwich-menu.png';
import CloseIcon from '../assets/images/close.png';

import '../styles/LogoMenu.scss';

const LogoMenu: React.FC = () => {
    const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
    const { isDateRevealed, loading } = useDateReveal();

    // Adicione o console.log para verificar o valor do contexto
    console.log('isDateRevealed:', isDateRevealed, 'loading:', loading);

    return (
        <header id="header-component">
            <img src={Logo} alt="Pixel Init" />

            <button onClick={() => setIsMenuVisible(true)} type="button" className="menu-button">
                <img src={MenuIcon} alt="menu" />
            </button>

            <nav className={classNames({ open: isMenuVisible })}>
                <button onClick={() => setIsMenuVisible(false)} type="button" className="menu-close">
                    <img src={CloseIcon} alt="Fechar menu" />
                </button>
                <ul>
                    <li>
                        {/* Verifica se ainda está carregando os dados */}
                        {loading ? (
                            <span>Carregando...</span>
                        ) : (
                            <>
                                {isDateRevealed ? (
                                    <Link to="/signup">Inscreva-se</Link>
                                ) : (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <Link style={{ pointerEvents: 'none', opacity: 0.5 }} to="/signup">Inscreva-se</Link>
                                )}
                            </>
                        )}
                    </li>
                    <li>
                        <a href="#palestrantes-section">Palestrantes</a>
                    </li>
                    <li>
                        <a href="#cronograma-section">Cronograma</a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default LogoMenu;
