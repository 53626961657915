import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PixelInitLanding from './pages/PixelInitLanding';
// import PixelthonLanding from './pages/PixelthonLanding';

// import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
// import SignIn from './pages/SignIn';
// import SignUp from './pages/SignUp';
import EmailConfirmation from './pages/EmailConfirmation';
// import RecoverPassword from './pages/RecoverPassword';
import Error404 from './pages/Error404';
// import Dashboard from './pages/app/Dashboard';
// import OutdatedSignUp from './pages/OutdatedSignUp';
import SignUp from './pages/SignUp';
import OutdatedSignUp from './pages/OutdatedSignUp';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

const App: React.FC = () => (
    <Switch>
        {/* Landing Pages */}
        <PublicRoute path="/landing/pixelinit">
            <PixelInitLanding />
        </PublicRoute>
        {/* <PublicRoute path="/landing/pixelthon">
            <PixelthonLanding />
        </PublicRoute> */}

        {/* Sistema */}
        {/* <PublicRoute path="/login">
            <SignIn />
        </PublicRoute> */}
        <PublicRoute path="/signup">
            <SignUp />
            {/* O redirecionamento para /outdated-signup é feito logo após o SignUp
            <Redirect to="/outdated-signup" /> */}
        </PublicRoute>
        <PublicRoute path="/outdated-signup">
            <OutdatedSignUp />
        </PublicRoute>
        <PublicRoute path="/confirm-email">
            <EmailConfirmation />
        </PublicRoute>
        <PublicRoute path="/terms">
            <Terms />
        </PublicRoute>
        <PublicRoute path="/privacy">
            <Privacy />
        </PublicRoute>
        {/* <PublicRoute path="/recover-password">
            <RecoverPassword />
        </PublicRoute> */}
        {/* <PrivateRoute path="/app">
            <Dashboard />
        </PrivateRoute> */}
        <Route path="/error/404">
            <Error404 />
        </Route>
        {/* Algumas configurações de rota */}
        <Route exact path="/">
            <Redirect to="/landing/pixelinit" />
        </Route>
        <Redirect to="/error/404" />
    </Switch>
);

export default App;
