import React from 'react';

import { Link } from 'react-router-dom';

import '../styles/LeftHeroContent.scss';

import WhiteLogo from '../assets/svgs/white-logo.svg';

const LeftHeroContent: React.FC = () => (
    <aside id="left-hero-content-component">
        <Link to="/">
            <img src={WhiteLogo} alt="Pixel Init" />
        </Link>
        <div className="content">
            <h1>
                o seu evento de tecnologia
                {' '}
                <span className="colored">favorito</span>
                .
            </h1>
            <h4>
                nos dias [REDACTED 👀] de [REDACTED 👀] participe do evento que irá te ajudar
                a dar seu primeiro passo na carreira de tecnologia.
            </h4>
        </div>
    </aside>
);

export default LeftHeroContent;
