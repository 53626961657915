import React from 'react';

import LeftHeroContent from '../components/LeftHeroContent';

import '../styles/OutdatedSignUp.scss';

const OutdatedSignUp: React.FC = () => (
    <div id="outdated-signup-page" className="signin-signup-view">
        <div className="row-wrapper">
            <LeftHeroContent />
            <main>

                <span className="title">As inscrições para o evento ainda não abriram!</span>

                {/* <Link to="/login">Já tem uma conta no Pixel Init? Faça login.</Link> */}
            </main>
        </div>
    </div>
);

export default OutdatedSignUp;
