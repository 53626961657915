import React from 'react';
import '../styles/TextSection.scss';

const TextSection: React.FC = () => (
    <div id="text-section">
        <div id="text">
        <p>
            O
            <strong> Pixel Init </strong>
            é um evento organizado pela Pixel, com o objetivo
            de levar o conhecimento acadêmico além dos muros da universidade, oferecendo
            conteúdo de qualidade e gratuito para todos os públicos e níveis de
            experiência. Com um espírito jovem e empreendedor, a missão do evento é
            aproximar a tecnologia das pessoas, despertando nelas o interesse pelo
            mercado de trabalho.
        </p>
        <p>
            A primeira edição do Pixel Init ocorreu em 2019, na sede da UFSC
            (Universidade Federal de Santa Catarina). Já em 2020, com o advento da
            pandemia, o evento passou por uma transformação e foi realizado de forma
            completamente remota.
        </p>
        <p>
            Na terceira edição, o foco foi reunir a comunidade de desenvolvedores,
            iniciantes e entusiastas da área de tecnologia, promovendo discussões sobre a
            vida acadêmica, o mercado de trabalho e o papel social da tecnologia.
        </p>
        <p>
            Em 2022, na quarta edição, o evento trouxe palestrantes de diferentes áreas
            tecnológicas, abrangendo tanto o eixo acadêmico quanto o mercado tech.
        </p>
        <p>
            Agora, na quinta edição, queremos mais uma vez
            <strong> unir a comunidade tecnológica de todo o Brasil</strong>
            ,envolvendo desde iniciantes até profissionais
            experientes e acadêmicos, para juntos explorarmos o futuro da tecnologia.
        </p>

        </div>
    </div>
);

export default TextSection;
